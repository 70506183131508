<template>
	<div>
		<div class="effe2-modal-background" v-show="display_modal_wl === true">
			<div class="effe2-modal" style="padding-bottom: 50px;">
				<div class="effe2-modal-title" style="margin-bottom: 50px;">Claim your WL</div>
				
				<div id="modal_claim_wl">
					<table>
						<tr>
							<td>Twitter handle</td>
							<td width="40%"><input type="text" v-model="twitter_handle" /></td>
						</tr>
						<tr>
							<td>Discord handle</td>
							<td width="40%"><input type="text" v-model="discord_handle" /></td>
						</tr>
						<tr>
							<td></td>
							<td width="40%"><div class="button button-primary" @click="save_handles();">Save it !</div></td>
						</tr>
					</table>
				</div>
				
				<br/>
				<br/>
				<span class="effe2-button"  @click="display_modal_wl = false">Close</span>
				
			</div>
		</div>
		
		<div class="effe2-modal-background" v-show="display_modal_winners === true" v-if="raffle !== null">
			<div class="effe2-modal" style="padding-bottom: 50px;">
				<div class="effe2-modal-title" style="margin-bottom: 50px;">Winners</div>
				
				
					<table>
						<tr>
							<td width="50%" style="text-align: right; padding-right: 10px;"><b>Wallet address</b></td>
							<td width="50%" style="text-align: left; padding-left: 10px;"><b>Tickets bought</b></td>
						</tr>
						<tr v-for="(winner, id) in raffle.winners_drawn" :key="id">
							<td style="text-align: right; padding-right: 10px;">{{ winner.wallet_address }}</td>
							<td style="text-align: left; padding-left: 10px;">{{ winner.tickets_bought }}</td>
						</tr>
					</table>
				
				
				<br/>
				<br/>
				<span class="effe2-button"  @click="display_modal_winners = false">Close</span>
				
			</div>
		</div>
		
		<div id="header">
			
			<div class="header-mobile mobile">
				<img :src="project.logo" />
				<div id="connect_wallet">
					<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
					<div v-else>
						{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
						Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
					</div>
				</div>
			</div>
			<div style="width: 320px; text-align: left; padding: 17px;"><img :src="project.logo" class="desktop" /></div>
			<div id="connect_wallet"  class="desktop">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
					Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
				</div>
			</div>
			<div class="advertisement">
				<a href="https://effe2.xyz/" target="_blank" style="color: #fff; text-decoration: none;"> 
					Powered by Effe²<br/>
					<span>Free tools for NFT projects on Solana</span>
				</a>
			</div>
		</div>
		
		
		<div id="raffles" v-if="raffle === null">
			<div class="raffle-container" v-for="(raffle, id) in raffles" :key="id">
				<template>
					<div class="raffle-background-container">
						<div class="raffle-type">
							{{ raffle.type.toUpperCase() }} | {{ raffle.price }} {{ project.token_name }}
							<span class="indicator-raffle indicator-raffle-live" v-if="raffle.live === true"></span>
							<span class="indicator-raffle indicator-raffle-ended" v-if="raffle.live === false"></span>
						</div>
						<div class="raffle" @click="open_raffle(raffle)" :style="'background: url('+raffle.nft_image+') no-repeat; background-size: contain;'"></div>
					</div>
					<div class="raffle-description" >
						<div class="raffle-title">{{ raffle.nft_name }}</div>
						<div class="raffle-info">
							<span class="raffle-tickets" v-if="raffle.tickets > 0">{{ raffle.tickets_sold }}/{{ raffle.tickets }} sold</span>
							<span class="raffle-tickets" v-if="raffle.tickets == 0 || raffle.tickets == null">150 sold</span>
							
							<span class="raffle-winners" v-if="raffle.winners == 1"><b>{{ raffle.winners }}</b> winner</span>
							<span class="raffle-winners" v-if="raffle.winners > 1"><b>{{ raffle.winners }}</b> winners</span>
						</div>
						<div class="raffle-button button-primary" v-if="raffle.live === true"  @click="open_raffle(raffle)">Enter raffle</div>
						<div class="raffle-button button-secondary" v-if="raffle.live === false && raffle.winner === false || (raffle.winner === true && raffle.claimed === true)"  @click="open_raffle(raffle)">View raffle</div>
						<div class="raffle-button button-primary" v-if="raffle.live === false && raffle.winner === true && raffle.claimed === false" @click="claim(raffle)">Claim !</div>
					</div>
				</template>
			</div>
		</div>
		<div id="raffle" v-if="raffle !== null">
		
			<div style="text-align: center;">
				<div class="go-back-to-raffles" @click="back_to_raffles()">Go back to raffles</div>
			</div>
			
			<div class="one-raffle">
			
				
			
				<div class="one-raffle-img" :style="'background-image: url('+raffle.nft_image+');'"></div>
			
				<div class="one-raffle-details">
				
					<h1>{{ raffle.nft_name }}</h1>
					
					<!-- countdown -->
					<div class="mobile" v-if="raffle.live === true"> 
						{{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
					</div>
					
					<div class="wrap desktop" v-if="raffle.live === true">  
						<div class="countdown">
							<div class="bloc-time days" :data-init-value="days">
								<div class="figure days days-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
								<div class="figure days days-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
							<div class="bloc-time hours" :data-init-value="hours">
								<div class="figure hours hours-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
								<div class="figure hours hours-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
							<div class="bloc-time min" :data-init-value="minutes">
								<div class="figure min min-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>        
								</div>
								<div class="figure min min-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
							<div class="bloc-time sec" :data-init-value="seconds">
								<div class="figure sec sec-1">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>          
								</div>
								<div class="figure sec sec-2">
									<span class="top">0</span>
									<span class="top-back">
										<span>0</span>
									</span>
									<span class="bottom">0</span>
									<span class="bottom-back">
										<span>0</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<!-- end countdown -->
					
					<div class="tickets-sold"  v-if="raffle.tickets > 0">{{ raffle.tickets_sold }} / {{ raffle.tickets }} tickets sold</div>
					<div class="tickets-sold"  v-else>{{ raffle.tickets_sold }} tickets sold</div>
					
					
					<div class="tickets-bought" v-if="raffle.tickets_bought !== false">{{ raffle.tickets_bought }} tickets bought</div>
					
					<div class="tickets-bought" v-if="raffle.winners_drawn.length > 0" @click="display_modal_winners = true" style="text-decoration: underline; cursor: pointer;">Display winners</div>
					
					<div>
						<div class="buy-tickets"  v-if="raffle.live === true">
							<div class="buy-tickets-minus" :class="{'button-primary': how_many_tickets > 1, 'button-secondary': how_many_tickets == 1}" @click="minus()">-</div>
							<div class="buy-tickets-count" :class="{'button-primary': !sold_out, 'button-secondary': sold_out}" @click="buy_tickets()">Buy {{ how_many_tickets }} tickets <span class="desktop">for {{ price }} {{ project.token_name }}</span></div>
							<div class="buy-tickets-plus" :class="{'button-primary': tickets_available, 'button-secondary': !tickets_available}" @click="plus()">+</div>
						</div>
						<div class="buy-tickets"  v-if="raffle.live === false && raffle.winner === true && raffle.claimed === false">
							<div class="button button-primary" @click="claim(raffle)">Claim !</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	</div>
</template>


<script>


import {buy, claim, check_winner} from '@/libs/rustProgram';
import {TweenMax, Quart} from 'gsap';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {getProject} from '@/libs/project';
import $ from 'jquery';
import { establishConnection, getTokenAccountForTokenAndOwner, getTokenAccountBalance, confirmTransaction } from "@/libs/solanaConnection";
import { PublicKey } from '@solana/web3.js';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

var connection = null;


export default {
	name: 'Raffles',
	components: {},

	data: function () {

		return {
			
			raffle: null,
			raffles: [],
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			how_many_tickets: 1,
			project: {},
			token_balance: 0,
			twitter_handle: '',
			discord_handle: '',
			display_modal_wl: false,
			display_modal_winners: false,
			raffle_id: '',
		}
	},
	
	computed: {
		
		price: function() {
			
			return this.how_many_tickets * this.raffle.price;
		},
		
		tickets_available: function() {
			
			if(this.raffle.tickets == 0)
				return true;
				
			if(this.raffle.tickets == null)
				return true;
				
			if(this.raffle.tickets - this.raffle.tickets_sold - this.how_many_tickets > 0)
				return true;
				
			return false;
		},
		
		sold_out: function() {
			
			if(this.raffle.tickets - this.raffle.tickets_sold <= 0)
				return true;
				
			return false;
		}
	},

	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
		
			$this.project = $this.$root.project;
		
			$this.get_raffles(); 
		});
		
		this.$root.$on('walletConnected', function() {
			
			$this.get_token_balance();
			$this.get_raffles(); 
			
			if($this.raffle !== null) {
				
				console.log("open raffle", $this.raffle);
				$this.open_raffle($this.raffle); 
			}
			
			// console.log("on checke");
			// check_winner('NhdMiyVir8uetkHRaDN6rNB6zYUPMcyb3VMgu4rtwDJ', getSolanaObject());
		});
		
		
		this.$root.get_project_ready = true;
	},
	mounted: function(){
	
	},

	methods: {
	
		back_to_raffles: function() {
			
			this.raffle = null;
		},
	
		get_token_balance: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			var buyer_token_account = await getTokenAccountForTokenAndOwner(this.project.token_address, getWalletAddress());
			
			var balance = await getTokenAccountBalance(new PublicKey(buyer_token_account));
			
			this.token_balance = balance;
		},
	
		plus() {
		
			if(this.raffle.tickets == 0 || this.raffle.tickets === null) {
			
				this.how_many_tickets += 1;
				return;
			}
			
			
			if(this.raffle.tickets - this.raffle.tickets_sold - this.how_many_tickets > 0)
				this.how_many_tickets += 1;
		},
	
		minus() {
		
			this.how_many_tickets -= 1;
			
			if(this.how_many_tickets <= 0)
				this.how_many_tickets = 1;
		},
		
		get_raffles: function() {
			
			var component = this;
			var project = this.project;
			
			var url;
			
			if(getWalletAddress() === false) {
			
				url = 'https://raffle-back.effe2.xyz/raffle/get-raffles/'+project.id;
			}
			else {
				
				url = 'https://raffle-back.effe2.xyz/raffle/get-raffles/'+project.id+'/'+getWalletAddress();
			}
			
			axios.get(url).then(function (result) {
				
				component.raffles = result.data.raffles;
			});
		},
		
		open_raffle: function(raffle) {
			
			this.raffle = raffle;
			
			var component = this;
			
			var url;
			
			if(getWalletAddress() === false) {
			
				url = 'https://raffle-back.effe2.xyz/raffle/get-raffle/'+raffle.id;
			}
			else {
				
				url = 'https://raffle-back.effe2.xyz/raffle/get-raffle/'+raffle.id+'/'+getWalletAddress();
			}
			
			axios.get(url).then(function (result) {
				
				component.days = result.data.raffle.days;
				component.hours = result.data.raffle.hours;
				component.minutes = result.data.raffle.minutes;
				component.seconds = result.data.raffle.seconds;
				
				component.raffle = result.data.raffle;
				
				component.create_countdown();
			});
			
			
		},
		
		claim_wl: async function(raffle) {
		
			this.display_modal_wl = true;
			this.raffle_id = raffle.id;
		},
		
		claim: async function(raffle) {
		
			if(raffle.type == 'wl')
				return this.claim_wl(raffle);
			
			var $this = this;
			
			$this.$root.$emit('openLoader');
			
			try {
			
				var signature = await claim(getSolanaObject(), getWalletAddress(), raffle.nft_address, raffle.escrow, this.project.admin_wallet);
				
				var confirmed_transaction = await confirmTransaction(signature);
				
				var data_transaction = {
					
					data: {
						
						raffle_id: raffle.id,
						buyer: getWalletAddress(),
					},
					signature: signature,
					type: 'claim',
					project_id: this.project.id,
				};
				
				await axios.post('https://raffle-back.effe2.xyz/raffle/save-transaction', data_transaction);
				
				if(confirmed_transaction === true) {
				
					$this.$root.modal = {title: 'Perfect !', text: "You successfully claimed your NFT !"};
					$this.$root.$emit('openModal');
					
					await axios.get('https://raffle-back.effe2.xyz/raffle/check-transactions');
				}
				else {
					
					$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					$this.$root.$emit('openModal');
				}
				
				
			}
			catch(e) {
				
				console.log('error');
				console.log(e);
				$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				$this.$root.$emit('openModal');
			}
			
			$this.$root.$emit('closeLoader');
			
		},
		
		
		create_countdown() {
		
			var days = this.days;
			var hours = this.hours;
			var minutes = this.minutes;
			var seconds = this.seconds;
			
			var $this = this;
			
			// Create Countdown
			var Countdown = {

				// Backbone-like structure
				$el: $('.countdown'),

				// Params
				countdown_interval: null,
				total_seconds     : 0,

				// Initialize the countdown  
				init: function() {

					// DOM
					this.$ = {
						days  : this.$el.find('.bloc-time.days .figure'),
						hours  : this.$el.find('.bloc-time.hours .figure'),
						minutes: this.$el.find('.bloc-time.min .figure'),
						seconds: this.$el.find('.bloc-time.sec .figure')
					};

					// Init countdown values
					this.values = {
						days  : days,
						hours  : hours,
						minutes: minutes,
						seconds: seconds,
					};
					
					// Initialize total seconds
					this.total_seconds = this.values.days * 60 * 60 * 24 + this.values.hours * 60 * 60 + (this.values.minutes * 60) + this.values.seconds;
					
					// Animate countdown to the end 
					this.count();    
				},

				count: function() {

					var that    = this,
					$day_1 = this.$.days.eq(0),
					$day_2 = this.$.days.eq(1),
					$hour_1 = this.$.hours.eq(0),
					$hour_2 = this.$.hours.eq(1),
					$min_1  = this.$.minutes.eq(0),
					$min_2  = this.$.minutes.eq(1),
					$sec_1  = this.$.seconds.eq(0),
					$sec_2  = this.$.seconds.eq(1);

					this.countdown_interval = setInterval(function() {
					
						if(that.total_seconds > 0) {

							--that.values.seconds;              

							if(that.values.minutes >= 0 && that.values.seconds < 0) {

								that.values.seconds = 59;
								--that.values.minutes;
							}

							if(that.values.hours >= 0 && that.values.minutes < 0) {

								that.values.minutes = 59;
								--that.values.hours;
							}

							if(that.values.days >= 0 && that.values.hours < 0) {

								that.values.hours = 23;
								--that.values.days;
							}

							// Update DOM values
							// Days
							that.checkHour(that.values.days, $day_1, $day_2);
							
							// Hours
							that.checkHour(that.values.hours, $hour_1, $hour_2);

							// Minutes
							that.checkHour(that.values.minutes, $min_1, $min_2);

							// Seconds
							that.checkHour(that.values.seconds, $sec_1, $sec_2);

							--that.total_seconds;
							
							$this.days = that.values.days;
							$this.hours = that.values.hours;
							$this.minutes = that.values.minutes;
							$this.seconds = that.values.seconds;
						}
						else {
							clearInterval(that.countdown_interval);
						}
					}, 1000);    
				},

				animateFigure: function($el, value) {

					var that         = this,
					$top         = $el.find('.top'),
					$bottom      = $el.find('.bottom'),
					$back_top    = $el.find('.top-back'),
					$back_bottom = $el.find('.bottom-back');

					// Before we begin, change the back value
					$back_top.find('span').html(value);

					// Also change the back bottom value
					$back_bottom.find('span').html(value);

					// Then animate
					TweenMax.to($top, 0.8, {
						rotationX           : '-180deg',
						transformPerspective: 300,
						ease                : Quart.easeOut,
						onComplete          : function() {

							$top.html(value);

							$bottom.html(value);

							TweenMax.set($top, { rotationX: 0 });
						}
					});

					TweenMax.to($back_top, 0.8, { 
						rotationX           : 0,
						transformPerspective: 300,
						ease                : Quart.easeOut, 
						clearProps          : 'all' 
					});    
				},

				checkHour: function(value, $el_1, $el_2) {

					var val_1       = value.toString().charAt(0),
					val_2       = value.toString().charAt(1),
					fig_1_value = $el_1.find('.top').html(),
					fig_2_value = $el_2.find('.top').html();

					if(value >= 10) {

						// Animate only if the figure has changed
						if(fig_1_value !== val_1) this.animateFigure($el_1, val_1);
						if(fig_2_value !== val_2) this.animateFigure($el_2, val_2);
					}
					else {

						// If we are under 10, replace first figure with 0
						if(fig_1_value !== '0') this.animateFigure($el_1, 0);
						if(fig_2_value !== val_1) this.animateFigure($el_2, val_1);
					}    
				}
			};

			// Let's go !
			Countdown.init();
		},
		
		save_handles: async function() {
			
			this.$root.$emit('openLoader');
			
			this.display_modal_wl = false;
			
			var data = {
				twitter_handle: this.twitter_handle, 
				discord_handle: this.discord_handle,
				raffle_id: this.raffle_id,
				wallet_address: getWalletAddress(),
			};
			
			await axios.post('https://raffle-back.effe2.xyz/raffle/save-handles', data);
			
			this.$root.modal = {title: 'Perfect !', text: "Data saved successfully !"};
			this.$root.$emit('openModal');
			
			this.$root.$emit('closeLoader');
		},
		
		buy_tickets: async function() {
		
			if(this.raffle.tickets - this.raffle.tickets_sold <= 0)
				return;
		
			if(getSolanaObject() == undefined) {
			
				this.$root.$emit('openPopup');
				
				return;
			}
			
			var signature;
			var data_transaction;
			var $this = this;
			
			$this.$root.$emit('openLoader');
			
			try {
				
				signature = await buy(getSolanaObject(), getWalletAddress(), this.how_many_tickets, this.raffle.escrow, this.project.token_associated_account, this.project.token_address);
				
				var confirmed_transaction = await confirmTransaction(signature);
				
				data_transaction = {
					
					data: {
						
						raffle_id: this.raffle.id,
						buyer: getWalletAddress(),
						tickets_bought: this.how_many_tickets,
					},
					signature: signature,
					type: 'buy',
					project_id: this.project.id,
				};
				
				await axios.post('https://raffle-back.effe2.xyz/raffle/save-transaction', data_transaction);
				
				if(confirmed_transaction === true) {
				
					$this.$root.modal = {title: 'Perfect !', text: "You successfully bought "+this.how_many_tickets+" ticket(s) !"};
					$this.$root.$emit('openModal');
					
					await axios.get('https://raffle-back.effe2.xyz/raffle/check-transactions');
					
					// update raffle
					this.raffle.tickets_bought = parseInt(this.raffle.tickets_bought) + parseInt(this.how_many_tickets);
					this.raffle.tickets_sold =  parseInt(this.raffle.tickets_sold) + parseInt(this.how_many_tickets);
				}
				else {
					
					$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					$this.$root.$emit('openModal');
				}
				
				
			}
			catch(e) {
				
				console.log('error');
				console.log(e);
				$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				$this.$root.$emit('openModal');
			}
			
			$this.$root.$emit('closeLoader');
		
		}
		
	},
	watch:{}
	}
</script>



